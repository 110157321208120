import React from 'react';
import { Line } from 'react-chartjs-2'
import axios from 'axios'
import { useState, useEffect } from 'react';
import { Flex, Select, Spinner, Stack } from '@chakra-ui/react'
import {
  Heading,
  Box,
  Text,
  VStack
} from "@chakra-ui/react";

import { Chart as
  ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import VoucherCard from './VoucherCard';
import { format, subWeeks } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function AdminDashboardBox() {
  const dropdown1 = ['Weekly','Daily'];
  const [durationType,setDurationType]=useState(null);
  const [label, setLabel] = useState([
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thrusday',
    'Friday',
    'Saturday',
    'Sunday'
  ]);
  const [chartTitle, setChartTitle] = useState('Daily Data Chart');

  const data={
    labels: ["12/May-18/May","17/May-23/May","24/May-30/May","31/May-06/June","07/June-13/June","14/June-20/June","21/June-27/June"],
    datasets:[
      {
        label:"Re",
        data:[200,3000,400,200,3000,400]
      },
      {
        label:"Reasd",
        data:[100,2343,9099]
      }
    ]
  };

  const getLast7Weeks = () => {
    return Array.from({ length: 7 }, (_, i) =>
      format(subWeeks(new Date(), i), 'MMM dd')
    ).reverse();
  };

  useEffect(()=>{
    setChartTitle(durationType === 'Daily' ? 'Daily Data Chart' : 'Weekly Data Chart');
    setLabel(durationType === 'Daily' ? [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thrusday',
      'Friday',
      'Saturday',
      'Sunday'
    ] : getLast7Weeks);
  },[durationType]);

  const dataCount = 7;
  const numberCfg = { count: dataCount, min: 0, max: 1000 };

  const generateRandomNumbers = ({ count, min, max }) => {
    return Array.from({ length: count }, () => Math.floor(Math.random() * (max - min + 1)) + min);
  };

  const graphData = {
    labels: label,
    datasets: [
      {
        label: 'Recharge',
        data: generateRandomNumbers(numberCfg),
        borderColor: 'rgba(254, 106, 116, 1)',
        backgroundColor: 'rgba(254, 106, 116, 0.5)',
      },
      {
        label: 'Voucher',
        data: generateRandomNumbers(numberCfg),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: 'Redeem Voucher',
        data: generateRandomNumbers(numberCfg),
        borderColor: 'rgba(82, 174, 90, 1)',
        backgroundColor: 'rgba(82, 174, 90, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
  };

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />

      <div
        style={{
          padding: "8px 20px 24px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack spacing={3} w='11vw'>
          <Select placeholder='Select Duration' size='md'
            onChange={(e)=>{
              setDurationType(e.target.value === 'Weekly' ? 'Weekly' : 'Daily');
            }}
          >
            {dropdown1.map((item)=>(
              <option value={item}>{item}</option>
            ))}
          </Select>
        </Stack>
      </div>

      <Flex mx="auto" my={5} maxW="4xl">
        <Box flex="4" mr={3} borderWidth="1px" borderColor="black" borderRadius="lg" px='auto' pl={2}>
          <Line data={graphData} options={options} />
        </Box>
        <Box flex="2">
          <VoucherCard />
        </Box>
      </Flex>
    </div>
  );
}

export default AdminDashboardBox;

