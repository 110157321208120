import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import AuthForm from './AuthForm';
import Header from '../common/Header';
import Footer from '../common/Footer';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { email, timeStamp } = useParams();
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState({
    Password: '',
    'Confirm Password': '',
  });

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password: formData.Password,
      confirmPassword: formData['Confirm Password'],
    };

    if (data.password !== data.confirmPassword) {
      return toast({
        title: 'Password mismatch',
        description: 'Password and Confirm Password doesnot match',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    try {
      const user = await axios.post(`${process.env.REACT_APP_API_END_POINT}/user/reset-password`, {
        email: email,
        password: data.password,
      });
      toast({
        title: 'Reset Password',
        description: `Password reset successfully for user ${user.data.user.email}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate('/user/login');
      }, 2000);
    } catch (error) {
      toast({
        title: 'Reset Password Failed',
        description: `Error: ${error.response.data.Error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const currentTime = Date.now();
    const timeDifference = currentTime - parseInt(timeStamp, 10);

    if (timeDifference > 1800000) {
      navigate('/page-not-found');
    } else {
      setIsValid(true);
    }
  }, [timeStamp, navigate]);

  return (
    isValid ? (
      <div>
        <Header title={'Reset Password'} />
        <AuthForm
          title={'Reset Password'}
          description={'Please enter your new password.'}
          fields={[
            ['Password', 'Confirm Password']
          ]}
          handleSubmit={handleSubmit}
          fieldValues={formData}
          handleChange={handleChange}
        />
        <Footer />
      </div>
    ) : (
      <div>Redirecting...</div>
    )
  );
};

export default ForgotPassword;
