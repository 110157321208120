import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import TableComp from '../Commons/Table';
import {
  Box,
  Button,
  VStack,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Spinner,
  HStack,
  IconButton,
  Flex,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {
  DeleteIcon,
} from '@chakra-ui/icons';

const GenerateVoucher = () => {
  const { isOpen: isGenerateOpen, onOpen: onGenerateOpen, onClose: onGenerateClose } = useDisclosure();
  const { isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose } = useDisclosure();

  const toast = useToast();

  const [noOfVouchers, setNoOfVouchers] = useState(null);
  const [amountOfVoucher, setAmountOfVoucher] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [voucherResponse, setVoucherResponse] = useState([]);
  const [listReload,setListReload] = useState(false);

  const handleGenerateVoucher = async () => {
    if (!noOfVouchers || !amountOfVoucher) {
      return alert('Please fill both the fields');
    }

    if (noOfVouchers > 100) {
      return alert('Maximum number of vouchers allowed is 100');
    }

    setLoading(true);
    setVoucherResponse([]);
    setListReload(true);
    try {
      for (let i = 0; i < noOfVouchers; i++) {
        const responseData = await axios.post(`${process.env.REACT_APP_API_END_POINT}/voucher`, {
          voucherAmount: amountOfVoucher,
          generatedBy: 'Admin',
          status: 'AdminGenerated',
        });
        setVoucherResponse((prevVoucherResponse) => [
          ...prevVoucherResponse,
          responseData.data.voucher
        ]);
      }
      setListReload(false);
      setAmountOfVoucher(null);
      setNoOfVouchers(null);
      onGenerateClose();
      alert('Vouchers generated successfully');
    } catch (error) {
      console.error('Error generating vouchers:', error);
      alert('Failed to generate vouchers');
      setAmountOfVoucher(null);
      setNoOfVouchers(null);
    } finally {
      setLoading(false);
    }
  };

  const handleImportVouchers = async () => {
    if (!file) {
      return alert('Please select a file to import');
    }
  
    setLoading(true);
    setVoucherResponse([]);
    setListReload(true);
    try {
      for (let i = 0; i < csvData.length; i++) {
        if (csvData[i].voucherAmount) {
          let responseData;
          try {
            responseData = await axios.post(`${process.env.REACT_APP_API_END_POINT}/voucher`, {
              voucherAmount: csvData[i].voucherAmount,
              voucherCode: csvData[i].voucherCode,
              generatedBy: 'Admin',
              status: 'Imported',
            });
            if (responseData?.data?.voucher) {
              setVoucherResponse((prevVoucherResponse) => [
                ...prevVoucherResponse,
                responseData.data.voucher,
              ]);
            } else if (responseData?.response?.data?.Error) {
              console.log('An error occurred while importing voucher');
            }
          } catch (error) {
            let allErroredVoucherCode = JSON.parse(localStorage.getItem('erroredVoucherCode'));
            console.log('All voucher codes: ', allErroredVoucherCode);
            if (!allErroredVoucherCode) {
              allErroredVoucherCode = [csvData[i].voucherCode];
            } else {
              allErroredVoucherCode.push(csvData[i].voucherCode);
            }
            localStorage.setItem('erroredVoucherCode', JSON.stringify(allErroredVoucherCode));
          }
        }
      }
      setListReload(false);
      setFile(null);
      setCsvData(null);
      onImportClose();
      localStorage.setItem('RecentlyAddedVouchers', JSON.stringify(voucherResponse));
      alert('Vouchers imported successfully');

      const allErroredVoucherCode = localStorage.getItem('erroredVoucherCode');
      console.log('All errored voucher codes are: ', allErroredVoucherCode);

      toast({
        title: 'Errored Voucher codes: ',
        description: `${allErroredVoucherCode}`,
        status: 'error',
        duration: 120000,
        isClosable: true,
      });
      localStorage.removeItem('erroredVoucherCode');
    } catch (error) {
      console.error('Error generating vouchers:', error);
      alert('Failed to generate vouchers');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    parseCSV(event.target.files[0]);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setCsvData(null);
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
      },
      error: (error) => {
        console.error('Error reading CSV file:', error);
      }
    });
  };

  const downloadSampleData = () => {
    const sampleCsv = `voucherCode,voucherAmount\n1234567890,100\n2345678901, 50`;
    const blob = new Blob([sampleCsv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'sample-data.csv');
  };

  useEffect(() => {
    if (voucherResponse.length > 0) {
      localStorage.setItem('RecentlyAddedVouchers', JSON.stringify(voucherResponse));
    }
  }, [listReload]);

  useEffect(() => {
    const vouchers = localStorage.getItem('RecentlyAddedVouchers');
    if (vouchers) {
      setVoucherResponse(JSON.parse(vouchers));
    }
  }, []);

  return (
    <div>
      <hr
        style={{
          height: '2px',
          width: '100%',
          marginBottom: '10px',
          color: 'darkgray',
        }}
      />

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="md"
        width="40vw"
        mx="auto"
        textAlign="center"
        mt={10}
        mb={5}
        py={20}
      >
        <VStack spacing={4} align="center">
          <Button
            width={["100%", "90%", "70%", "50%", "40%"]}
            color="#000000"
            bg="#C0DDFF"
            borderColor="darkgray"
            borderWidth="1px"
            _hover={{ bg: "#98C6FF", color: "#000000" }}
            onClick={onGenerateOpen}
          >
            Generate Voucher
          </Button>
          <Text fontSize="lg" color="gray.500">
            OR
          </Text>
          <Button
            width={["100%", "90%", "70%", "50%", "40%"]}
            color="#000000"
            bg="#C0DDFF"
            borderColor="darkgray"
            borderWidth="1px"
            _hover={{ bg: "#98C6FF", color: "#000000" }}
            onClick={onImportOpen}
          >
            Import Voucher
          </Button>
        </VStack>
      </Box>

      {/* Generate Voucher Modal */}
      <Modal isOpen={isGenerateOpen} onClose={onGenerateClose}>
        <ModalOverlay />
        <ModalContent
          width={["100vw", "90vw", "70vw", "50vw", "40vw"]}
          height={"auto"}
        >
          <ModalHeader textAlign='center' mb={7} bg={'#C0DDFF'}>Generate Voucher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align='center'>
              <Input
                placeholder='No. of Vouchers'
                width='70%'
                type='number'
                value={noOfVouchers}
                onChange={(e) => setNoOfVouchers(e.target.value)}
              />
              <Text color='gray.500' mb={5}>
                Max number of vouchers allowed is 100
              </Text>
              <Flex wrap="wrap" justify="center">
                {[1, 2, 5, 10, 20, 50, 100, 200, 500, 1000].map(amount => (
                  <Button
                    key={amount}
                    m={1}
                    colorScheme={amountOfVoucher === amount ? 'blue' : 'gray'}
                    onClick={() => setAmountOfVoucher(amount)}
                    fontWeight={amountOfVoucher === amount ? 'bold' : 'normal'}
                  >
                    ${amount}
                  </Button>
                ))}
              </Flex>
              <Text color='gray.500' mb={5}>
                The amount selected is for one voucher
              </Text>
              {loading ? (
                <Button colorScheme='blue' width='70%' disabled>
                  <Spinner size="lg" />
                </Button>
              ) : (
                <Button
                  colorScheme='blue'
                  width='70%'
                  onClick={handleGenerateVoucher}
                >
                  Generate Vouchers
                </Button>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Import Voucher Modal */}
      <Modal isOpen={isImportOpen} onClose={onImportClose}>
        <ModalOverlay />
        <ModalContent
          width={["100vw", "90vw", "70vw", "50vw", "40vw"]}
          height={"auto"}
        >
          <ModalHeader textAlign='center' mb={7} bg={'#C0DDFF'}>Import Voucher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align='center'>
              {!file && (
                <Input
                  type='file'
                  accept='.csv'
                  onChange={handleFileChange}
                  width='70%'
                  mb={5}
                  pt={1}
                />
              )}
              {file && (
                <HStack spacing={4} align='center' mb={5}>
                  <Text>{file.name}</Text>
                  <IconButton
                    aria-label='Delete file'
                    icon={<DeleteIcon />}
                    colorScheme='red'
                    onClick={handleDeleteFile}
                  />
                </HStack>
              )}
              {loading ? (
                <Button
                  colorScheme='blue'
                  width='70%'
                  mb={5}
                  disabled
                >
                  <Spinner size="lg" />
                </Button>
              ) : (
                <Button
                  colorScheme='blue'
                  width='70%'
                  onClick={handleImportVouchers}
                  mb={5}
                >
                  Import Vouchers
                </Button>
              )}
              <Button
                colorScheme='blue'
                width='70%'
                mb={5}
                onClick={downloadSampleData}
              >
                Download Sample Data
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {voucherResponse.length > 0 && (
        <Box textAlign="center" my={5}>
          <Heading as="h1" size="lg" my={10}>
            Recently Added Vouchers
          </Heading>
          <TableComp
            headerData={['voucherCode', 'voucherAmount', 'status']}
            listData={voucherResponse}
            listReload={listReload}
          />
        </Box>
      )}
    </div>
  );
};

export default GenerateVoucher;
