import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Flex,
  Text,
  VStack,
  Input,
  Button,
  useToast,
  Heading,
} from '@chakra-ui/react';
import TableComp from '../Commons/Table';
import { CloseIcon } from '@chakra-ui/icons';

const Voucher = () => {
  const [vouchers, setVouchers] = useState([
    { id: '1', amount: 1, quantity: 0, active: 0, inactive: 0 },
    { id: '2', amount: 2, quantity: 0, active: 0, inactive: 0 },
    { id: '5', amount: 5, quantity: 0, active: 0, inactive: 0 },
    { id: '10', amount: 10, quantity: 0, active: 0, inactive: 0 },
    { id: '20', amount: 20, quantity: 0, active: 0, inactive: 0 },
    { id: '50', amount: 50, quantity: 0, active: 0, inactive: 0 },
    { id: '100', amount: 100, quantity: 0, active: 0, inactive: 0 },
    { id: '200', amount: 200, quantity: 0, active: 0, inactive: 0 },
    { id: '500', amount: 500, quantity: 0, active: 0, inactive: 0 },
    { id: '1000', amount: 1000, quantity: 0, active: 0, inactive: 0 },
  ]);
  const [allVouchers, setAllVouchers] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [filteredVouchers, setFilteredVouchers] = useState(vouchers);
  const [voucherDetails, setVoucherDetails] = useState(null);
  const [utilizedData, setUtilizedData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const toast = useToast();

  const fetchAllVouchers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/voucher`);
    const allVouchers = response.data.vouchers;
    setAllVouchers(allVouchers);

    const updatedVouchers = vouchers.map((voucher) => {
      const matchingVouchers = allVouchers.filter(
        v => v.voucherAmount === voucher.amount
      );

      const totalInactives = matchingVouchers.filter(voucher => voucher.status === 'Utilized' || voucher.status === 'Paused').length;
      const totalActives = matchingVouchers.length - totalInactives;

      return {
        ...voucher,
        quantity: matchingVouchers.length,
        active: totalActives,
        inactive: totalInactives,
      };
    });

    setVouchers(updatedVouchers);
    setFilteredVouchers(updatedVouchers);
  };

  useEffect(() => {
    fetchAllVouchers();
  }, []);

  const handleSearch = async () => {
    if (!voucherCode) {
      return toast({
        title: 'Error',
        description: 'Please enter a Voucher Code',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/voucher/${voucherCode}`);
      const voucher = response.data.voucher;

      const statusMessage = voucher.status === 'Utilized' || voucher.status === 'Paused'
      ? 'The voucher is completely used'
      : 'The voucher is currently active';

      const assignedMessage = voucher.assignedTo
        ? `Assigned to: ${voucher.assignedTo}`
        : 'Not assigned to anyone';

      setUtilizedData(voucher.utilizedBy);

      const resultMessage = `Voucher code: ${voucherCode}\n
      Voucher amount: ${voucher.voucherAmount}\n
      ${assignedMessage}\n
      ${statusMessage}\n`;

      setVoucherDetails(resultMessage);
      setVoucherCode('');
    } catch (err) {
      return toast({
        title: 'Error',
        description: err.response.data.Error,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleClose = async () => {
    setVoucherDetails(null);
  }

  const handleCardClick = async (voucher) => {
    const selectedVouchers = allVouchers.filter(v => v.voucherAmount === voucher.amount);
    setTableData(selectedVouchers);
  }

  return (
    <Box>
      <hr
        style={{
          height: '2px',
          width: '100%',
          marginBottom: '10px',
          color: 'darkgray',
        }}
      />

      <Flex mb={4} mt={7} justify="center">
        <Input
          placeholder="Search Voucher by Voucher Code"
          value={voucherCode}
          onChange={(e) => setVoucherCode(e.target.value)}
          width="300px"
          mr={2}
        />
        <Button onClick={handleSearch} colorScheme="blue">
          Search
        </Button>
      </Flex>

      {voucherDetails && (
        <Box
          mt={4}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          textAlign="center"
          width="50vw"
          mx="auto"
          position="relative"
        >
          <CloseIcon
            position="absolute"
            top={4}
            right={4}
            cursor="pointer"
            onClick={handleClose}
          />
          <Text whiteSpace="pre-wrap">{voucherDetails}</Text>
          {utilizedData.length > 0 && (
            <Box textAlign="center" mt={10} mb={5}>
              <Heading as="h5" size="md" my={5}>
                Voucher utilizations
              </Heading>
              <TableComp
                headerData={['meterNo', 'userId', 'utilizationAmount', 'date']}
                listData={utilizedData}
                listReload={false}
              />
            </Box>
          )}
        </Box>
      )}

      <Flex wrap="wrap" justify="center" my={7}>
        {filteredVouchers.map((voucher) => (
          <Box
            key={voucher.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            m={2}
            p={4}
            width="200px"
            textAlign="center"
            boxShadow="md"
            _hover={{ boxShadow: 'xl' }}
            onClick={() => handleCardClick(voucher)}
          >
            <Text fontSize="2xl" fontWeight="bold">
              ${voucher.amount}
            </Text>
            <VStack spacing={1} mt={2}>
              <Text fontSize="lg">{voucher.quantity} vouchers</Text>
              <Text fontSize="sm" color="gray.500">
                {voucher.active} active vouchers
              </Text>
              <Text fontSize="sm" color="gray.500">
                {voucher.inactive} inactive vouchers
              </Text>
            </VStack>
          </Box>
        ))}
      </Flex>

      {tableData.length > 0 && (
        <Box textAlign="center" my={5}>
          <Heading as="h1" size="lg" my={10}>
            Voucher Data
          </Heading>
          <TableComp
            headerData={['voucherCode', 'voucherAmount', 'assignedTo', 'status', 'generatedBy']}
            listData={tableData}
            listReload={false}
          />
        </Box>
      )}
    </Box>
  );
};

export default Voucher;
