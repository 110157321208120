import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthForm from './AuthForm';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { useToast } from '@chakra-ui/react';

const Signup = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    'Meter Number': ''
  });

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const data = {
      name: formData.Name,
      email: formData.Email,
      phone: formData.Phone,
      meterNumber: formData['Meter Number'],
    }

    try {
      const user = await axios.post(`${process.env.REACT_APP_API_END_POINT}/user/signup`, {
        name: data.name,
        email: data.email,
        phone: data.phone,
        meterNumber: data.meterNumber,
      });
      toast({
        title: 'Signup Success',
        description: `User with id ${user.data.user._id} signed up successfully, Please login to proceed further`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/user/login');
    } catch (error) {
      toast({
        title: 'Signup Failed',
        description: `Error: ${error.response.data.Error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('user-access-token');
    if (accessToken) {
      navigate('/user/electricity');
    } else {
      navigate('/user/signup');
    }
  }, []);

  return (
    <div>
      <Header />

      <AuthForm
        title={'Sign Up'}
        description={'Lorem Ipsum is simply dummy text of the printing and typesett ing industry. Lorem Ipsum has been the industry'}
        fields={[
          ['Name', 'Email'],
          ['Phone', 'Meter Number'],
        ]}
        handleSubmit={handleSignup}
        fieldValues={formData}
        handleChange={handleChange}
      />

      <Footer />
    </div>
  );
}

export default Signup;
