import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Button } from '@chakra-ui/react';

const PaymentForm = () => {
  const [stripePromise, setStripePromise] = useState('');
  const [requestUrl, setRequestUrl] = useState('');

  const handleClick = async (event) => {
    event.preventDefault();

    const stripe = await stripePromise;
    const session = await axios.post(requestUrl);

    const result = await stripe.redirectToCheckout({
      sessionId: session.data.session,
    });

    if (result.error) {
        alert(result.error.message);
    }
  };

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
    setRequestUrl(`${process.env.REACT_APP_API_END_POINT}/transaction/create-payment-session`);
  }, []);

  return (
    <Button onClick={handleClick}>
      Pay Now
    </Button>
  );
}

export default PaymentForm;