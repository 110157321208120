import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

function VoucherCard() {
  const voucherBalance = 2500;
  const voucherAddition = 300;
  const voucherRedemption = 150;

  return (
    <Box p={5} maxW='sm' borderWidth='1px' borderColor='black' borderRadius='lg' mt={14} ml={4}>
      <Heading as='h4' size='md' mb={10}>
        Voucher Balance: ${voucherBalance}
      </Heading>
      <VStack align='start' spacing={4}>
        <Box>
          <Text fontSize='md' fontWeight='bold' mb={0}>
            Voucher Addition: ${voucherAddition}
          </Text>
        </Box>
        <Box>
          <Text fontSize='md' fontWeight='bold'>
            Voucher Redemption: ${voucherRedemption}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default VoucherCard;
