import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Center, Select, Stack } from '@chakra-ui/react';
import TableComp from '../Commons/Table';

function AdminTransaction() {
  const [listReload,setListReload]=useState(false);
  const [transactionType,setTransactionType]=useState('all');
  const [listData,setListData]=useState([]);
  const dropdown2=[
    'All',
    'Voucher',
    'Recharge',
    'Redeem Voucher',
  ];

  useEffect(()=>{
    setListReload(true);
    const dateNow = new Date();
    const response = [
      { userPhoneNo: '919983221046', amount: '500', transactionType: 'Recharge', createdAt: dateNow.toString(), voucherBalance: '450' },
      { userPhoneNo: '919983221047', amount: '600', transactionType: 'Voucher', createdAt: dateNow.toString(), voucherBalance: '390' },
      { userPhoneNo: '919983221048', amount: '5300', transactionType: 'Recharge', createdAt: dateNow.toString(), voucherBalance: '3450' },
      { userPhoneNo: '919983221049', amount: '300', transactionType: 'Redeem Voucher', createdAt: dateNow.toString(), voucherBalance: '68' },
      { userPhoneNo: '919983221045', amount: '4800', transactionType: 'Voucher', createdAt: dateNow.toString(), voucherBalance: '3879' },
      { userPhoneNo: '919983221044', amount: '9300', transactionType: 'Recharge', createdAt: dateNow.toString(), voucherBalance: '6895' },
    ];

    setListReload(false);
    if (transactionType === 'recharge') {
      setListData(
        response.filter((dt) => dt.transactionType === 'Recharge')
      );
    } else if (transactionType === 'voucher') {
      setListData(
        response.filter((dt) => dt.transactionType === 'Voucher')
      );
    } else if (transactionType === 'redeem') {
      setListData(
        response.filter((dt) => dt.transactionType === 'Redeem Voucher')
      );
    } else {
      setListData(response);
    }
  }, [transactionType]);

  return (
    <div>
      <hr
        style={{
          height: '2px',
          width: '100%',
          marginBottom: '10px',
          color: 'darkgray',
        }}
      />

      <Stack spacing={3} w={['46vw', '30vw', '24vw', '18vw', '13vw']}>
        <Select placeholder='Transaction Type' size='md'
          onChange={(e) => {
            if (e.target.value === 'Recharge') {
              setTransactionType('recharge')
            } else if (e.target.value === 'Voucher') {
              setTransactionType('voucher')
            } else if (e.target.value === 'Redeem Voucher') {
              setTransactionType('redeem')
            } else {
              setTransactionType('all')
            }
          }}
          style={{
            width: '170px',
            padding: '0px 10px',
            height: '40px',
            margin: '10px 0px 13px 15px',
          }}
        >
          {dropdown2.map((item)=>(
            <option value={item}>{item}</option>
          ))}
        </Select>
      </Stack>

      <TableComp
        headerData={[
          'userPhoneNo',
          'amount',
          'transactionType',
          'voucherBalance',
          'createdAt',
        ]}
        listData={listData}
        listReload={listReload}
      />
    </div>
  );
}

export default AdminTransaction;
