import React from 'react';

const PageNotFound = () => {
  return (
    <div>
      Page Not Found
    </div>
  );
}

export default PageNotFound;
