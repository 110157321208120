import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import card1 from '../../../assets/card-1.png';
import card2 from '../../../assets/card-2.png';
import card3 from '../../../assets/card-3.png';

const Form = ({ title, description, fields, handleSubmit, fieldValues, handleChange, suggestedAmount }) => {
  const handleSuggestedAmountClick = (amount) => {
    handleChange('Amount')({ target: { value: amount } });
  };

  return (
    <div className="plan-form">
      <div className="header">
        <div className="header-text">
          <h1>{title}</h1>
          <p className="plan-para">{description}</p>
        </div>
        <img src={title === 'Purchase Electricity' ? card1 : title === 'Purchase Gift Card' ? card2 : card3} alt='Card image' />
      </div>

      <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <div className="plan-form-group" key={index}>
            <label htmlFor={field}>{field}</label>
            <input
              type={field.toLowerCase().includes('gift card valid till') ? 'date' : 'text'}
              id={field}
              placeholder={`Enter Your ${field}`}
              value={fieldValues[field]}
              onChange={handleChange(field)}
              required
            />
            {field === 'Amount' && suggestedAmount && (
              <div className='suggested'>
                <label>Suggested Amount</label>
                <div className="suggested-amounts">
                  {suggestedAmount.map((amount, idx) => (
                    <button
                      type="button"
                      key={idx}
                      className="suggested-amount-button"
                      onClick={() => handleSuggestedAmountClick(amount)}
                    >
                      {amount}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
        <button type="submit" className="plan-button">Submit</button>
      </form>
    </div>
  );
};

Form.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fieldValues: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  suggestedAmount: PropTypes.arrayOf(PropTypes.string),
};

export default Form;
