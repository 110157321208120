import React, { useState } from 'react';
import { Spinner, Button } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

const TableComp = ({ headerData, listData, listReload }) => {
  const [activeVoucherCode, setActiveVoucherCode] = useState(null);

  const headerList = {
    userPhoneNo: 'User Phone No.',
    amount: 'Amount',
    transactionType: 'Transaction Type',
    createdAt: 'Created at',
    voucherBalance: 'Voucher Balance',
    voucherCode: 'Voucher Code',
    voucherAmount: 'Voucher Amount',
    status: 'Voucher Status',
    meterNo: 'Meter No',
    userId: 'User Id',
    utilizationAmount: 'Utilized Amount',
    date: 'Date',
    generatedBy: 'Generated By',
    assignedTo: 'Assigned To',
  };

  const handleViewClick = (voucherCode) => {
    if (activeVoucherCode === voucherCode) {
      setActiveVoucherCode(null);
    } else {
      setActiveVoucherCode(voucherCode);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div style={{ margin: '15px 10px' }}>
      <TableContainer>
        {listReload ? (
          <div className='flex flex-col items-center justify-center h-screen w-screen'>
            <Spinner size='sm' />
          </div>
        ) : (
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Sr No.</Th>
                {headerData.map((item) => (
                  <Th key={item} style={{ textAlign: 'center' }}>{headerList[item]}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {listData.length > 0 &&
                listData.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {headerData.map((key, subIndex) => (
                      <Td key={subIndex} style={{ textAlign: 'center' }}>
                        {key === 'voucherCode' ? (
                          activeVoucherCode === item[key] ? (
                            item[key]
                          ) : (
                            <Button
                              bg='blue.500'
                              color='white'
                              _hover={{ bg: 'blue.600' }}
                              onClick={() => handleViewClick(item[key])}
                            >
                              View
                            </Button>
                          )
                        ) : key === 'amount' || key === 'voucherBalance' || key === 'voucherAmount' ? (
                          `$${item[key]}`
                        ) : key === 'date' || key === 'createdAt' ? (
                          formatDate(item[key])
                        ) : (
                          item[key]
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default TableComp;
