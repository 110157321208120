import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import WebsiteLogo from '../../../assets/website-logo.png';

const Header = ({ title, handleLogout }) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 991);

  const handleClick = () => {
    navigate(title ? '/user/signup' : '/user/login');
  };

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 991);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      <section className="top-nav">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="logo-sec" onClick={() => window.location.href='https://www.ezcurrent.com'}>
                <img src={WebsiteLogo} alt='Website logo' />
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="menu-sec">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <button
                    className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`}
                    type="button"
                    aria-controls="navbarNav"
                    aria-expanded={!isCollapsed}
                    aria-label="Toggle navigation"
                    onClick={handleToggle}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className={`collapse navbar-collapse ${!isCollapsed ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a className="nav-link" href="https://www.ezcurrent.com">HOME</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="https://www.ezcurrent.com/about.html">ABOUT</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link active" href="https://app.ezcurrent.com/user/electricity">SERVICES</a>
                      </li>

                      {isMobileView && (
                        <li className="nav-item">
                          <a className="nav-link" href="#" onClick={handleLogout ? handleLogout : handleClick}>
                            {title ? 'Sign Up' : handleLogout ? 'Log out' : 'Log in'}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <div className="contact-sec">
                <div className="icon">
                  <a href="#" className="btn" onClick={handleLogout ? handleLogout : handleClick}>
                    {title ? 'Sign Up' : handleLogout ? 'Log out' : 'Log in'}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
