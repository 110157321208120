import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import card1 from '../../../assets/card-1.png';
import card2 from '../../../assets/card-2.png';
import card3 from '../../../assets/card-3.png';

const NavigationCard = ({ title, navigationUrl }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigationUrl);
  };

  const getButtonLabel = () => {
    const firstWord = title.split(' ')[0];
    return firstWord === 'Purchase' ? 'Purchase Now' : firstWord === 'Redeem' ? 'Redeem Now' : 'Click Here';
  };

  return (
    <div className='navigation-card'>
      <img src={title === 'Purchase Electricity' ? card1 : title === 'Purchase Gift Card' ? card2 : card3} alt='Card image' width={60} />
      <p>{title}</p>
      <button className='navigation-card-button' onClick={handleClick}>{getButtonLabel()}</button>
    </div>
  );
};

NavigationCard.propTypes = {
  title: PropTypes.string.isRequired,
  navigationUrl: PropTypes.string.isRequired,
};

export default NavigationCard;
