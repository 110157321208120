import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  useToast,
} from '@chakra-ui/react';

const AuthForm = ({ title, description, fields, handleSubmit, fieldValues, handleChange }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleClick = () => {
    const url = title === 'Login' ? '/user/signup' : '/user/login';
    navigate(url);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      if (fieldValues.Phone === null || fieldValues.Phone === '') {
        toast({
          title: 'OTP Error',
          description: 'Please fill a valid phone number',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      await axios.post(`${process.env.REACT_APP_API_END_POINT}/user/send-otp`, {
        phone: fieldValues.Phone,
      });
      setOtpSent(true);
      setTimer(30);
      setIsResendDisabled(true);
      toast({
        title: 'OTP Sent Successfully',
        description: `OTP sent to your email`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'OTP Sent Failed',
        description: `Error: ${error.response.data.Error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="auth-form">
      <h1>{title}</h1>
      <p className="auth-para">{description}</p>
      <form onSubmit={handleSubmit}>
        {fields.map((row, rowIndex) => (
          <div className="form-row" key={rowIndex}>
            {row.map((field, fieldIndex) => (
              <div
                className={`form-group ${fieldIndex === 0 ? 'form-first-input' : ''}`}
                key={fieldIndex}
              >
                {/* Check if the field is "Password" */}
                {field === "Password" && otpSent ? (
                  <>
                    <label htmlFor="otp">OTP</label>
                    <input
                      type="text"
                      id="otp"
                      placeholder="Enter Your OTP"
                      onChange={handleChange(field)}
                      required
                    />
                  </>
                ) : field === "Password" && !otpSent ? (
                  <Button
                    className="auth-button"
                    onClick={handleSendOtp}
                    isFullWidth
                    bg="#FFDD00"
                    _hover={{ bg: "#E6C200" }}
                    marginTop="30px"
                  >
                    Send OTP
                  </Button>
                ) : (
                  <>
                    <label htmlFor={field}>{field}</label>
                    <input
                      type={field.toLowerCase().includes('phone') ? 'number' : 'text'}
                      id={field}
                      placeholder={`Enter Your ${field}`}
                      value={fieldValues[field]}
                      onChange={handleChange(field)}
                      required
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        ))}

        {/* Timer and Resend OTP */}
        {isResendDisabled && (
          <div style={{ textAlign: 'right', cursor: 'pointer', color: timer === 0 ? 'blue' : 'gray' }}>
            {timer > 0 ? (
              <p>Resend OTP in {timer} seconds</p>
            ) : (
              <p onClick={handleSendOtp}>Resend OTP</p>
            )}
          </div>
        )}

        <button type="submit" className="auth-button">{title}</button>
      </form>

      <h5 style={{ textAlign: 'center', margin: '10px 0' }}>OR</h5>
      <button className="auth-or-button" onClick={handleClick}>
        {title === 'Login' ? 'Sign Up' : 'Login'}
      </button>
    </div>
  );
};

AuthForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fieldValues: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AuthForm;
