import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  useToast,
  Heading,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png';

const AdminLogin = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access-token');
    if (token) {
      navigate('/admin/dashboard');
    } else {
      navigate('/admin/login');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/admin/login`, {
        username,
        password
      });

      localStorage.setItem('access-token', response.data.access_token);
      navigate(`/admin/dashboard`);
      toast({
        title: 'Admin Login Successful',
        description: 'You have successfully logged in.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Admin Login Failed',
        description: 'The username or password entered is incorrect.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ marginTop: '30px' }}>
        <img
          src={logo}
          alt='ez-current logo image'
          style={
            {
              width: '120px',
              margin: '0 auto',
            }
          }/>
      </div>

      <Center h='20vh'>
        <Heading as='h1' size='xl' fontWeight='bold'>
            Ez-current Admin Login
        </Heading>
      </Center>

      <Box
        maxW='sm' 
        borderWidth='1px' 
        borderRadius='lg' 
        overflow='hidden' 
        p='6'
        m='auto'
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl id='username' mb='4'>
            <FormLabel>Username</FormLabel>
            <Input
              type='text' 
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl id='password' mb='4'>
            <FormLabel>Password</FormLabel>
            <Input
              type='password' 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormControl>
      
          <Button type='submit' colorScheme='teal' width='full' mt='4' disabled={loading}>
            {loading ? <Spinner size='sm' /> : 'Login'}
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default AdminLogin;
