import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthForm from './AuthForm';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { useToast } from '@chakra-ui/react';

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    Phone: '',
    Password: '',
  });

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      phone: formData.Phone,
      password: formData.Password,
    };

    try {
      const user = await axios.post(`${process.env.REACT_APP_API_END_POINT}/user/login`, {
        phone: data.phone,
        password: data.password,
      });
      localStorage.setItem('user-access-token', user.data.user.access_token);
      localStorage.setItem('user_name', user.data.user.name)
      window.location.href = '/user/signup';
    } catch (error) {
      toast({
        title: 'Login Failed',
        description: `Error: ${error.response.data.Error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('user-access-token');
    if (accessToken) {
      navigate('/user/electricity');
    } else {
      navigate('/user/login');
    }
  }, []);

  return (
    <div>
      <Header title={'Sign up'} />

      <AuthForm
        title={'Login'}
        description={'Lorem Ipsum is simply dummy text of the printing and typesett ing industry. Lorem Ipsum has been the industry'}
        fields={[
          ['Phone', 'Password']
        ]}
        handleSubmit={handleLogin}
        fieldValues={formData}
        handleChange={handleChange}
      />

      <Footer />
    </div>
  );
}

export default Login;
