import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Dashboard from './DashboardBox';
import Transaction from './Transaction';

import logo from '../assets/logo.png';
import Voucher from './Voucher';
import GenerateVoucher from './GenerateVoucher';

function AdminDashboard() {
  const navigate = useNavigate();
  const tabNames = ['Dashboard', 'Transaction', 'Vouchers', 'Generate Vouchers'];
  const [index, setIndex] = useState(0);

  const tabIndex = {
    0: <Dashboard />,
    1: <Transaction />,
    2: <Voucher />,
    3: <GenerateVoucher />,
  };

  useEffect(() => {
    const token = localStorage.getItem('access-token');
    if (!token) {
      navigate('/admin/login');
    } else {
      navigate('/admin/dashboard');
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access-token');
    navigate('/admin/login');
  }

  return (
    <div>
      <header style={{
        background: '#C0DDFF',
        position: 'relative',
        height: '80px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
        <div style={{ margin: 'auto 0px auto 20px' }}>
          <img
            src={logo}
            alt='ez-current logo image'
            style={{
              width: '50px',
              margin: '0 auto',
            }}/>
        </div>
        <button
          className='logout-button'
          onClick={handleLogout}
        >
          Logout
        </button>
      </header>
      <div style={{
        maxHeight: '100vh',
        minHeight: '100vh',
        width: '100vw',
      }}>
        <div
          style={{
            minHeight: '100vh',
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <div
            style={{
              minWidth: '100%',
              maxWidth: '80vw',
              border: '1px solid darkgray',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                padding: '12px',
                display: 'flex',
              }}
            >
              {tabNames.map((tab, i) => {
                return (
                  <button
                    key={tab}
                    className={`tab-button ${index === i ? 'selected' : ''}`}
                    onClick={() => setIndex(i)}
                  >
                    {tab}
                  </button>
                );
              })}
            </div>

            <div
              style={{
                flex: '1',
                width: '100%',
                overflow: 'auto',
              }}
            >
              <div style={{ padding: '8px' }}>{tabIndex[index]}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
