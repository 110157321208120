import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminLogin from '../Components/AdminLogin';
import AdminDashboard from '../Components/Dashboard';
import PaymentForm from '../Components/Payment';
import UserSignup from '../Components/user/auth/Signup';
import UserLogin from '../Components/user/auth/Login';
import PurchaseElectricity from '../Components/user/plans/PurchaseElectricity';
import PurchaseGiftCard from '../Components/user/plans/PurchaseGiftCard';
import RedeemGiftCard from '../Components/user/plans/RedeemGiftCard';
import ForgotPassword from '../Components/user/auth/ForgotPassword';
import PageNotFound from '../Components/user/common/PageNotFound';

export default function PageRoute() {
  const accessToken = localStorage.getItem('access-token');
  const userAccessToken = localStorage.getItem('user-access-token');

  return (
    <BrowserRouter>
      <Routes>
        {/* Main Route */}
        <Route path='/*' element={accessToken ? <AdminDashboard /> : userAccessToken ? <PurchaseElectricity /> : <UserLogin /> } />

        {/* Admin Routes */}
        <Route path='/admin/*' element={accessToken ? <AdminDashboard /> : <AdminLogin /> } />
        <Route path='/admin/login' element={<AdminLogin />} />
        <Route path='/admin/dashboard' element={<AdminDashboard />} />

        {/* User Routes */}
        <Route path='/user/*' element={userAccessToken ? <PurchaseElectricity /> : <UserLogin />} />
        <Route path='/user/login' element={<UserLogin />} />
        <Route path='/user/signup' element={<UserSignup />} />

        {/* User Plan Routes */}
        <Route path='/user/electricity' element={userAccessToken ? <PurchaseElectricity /> : <UserLogin />} />
        <Route path='/user/giftcard' element={userAccessToken ? <PurchaseGiftCard /> : <UserLogin />} />
        <Route path='/user/redeem' element={userAccessToken ? <RedeemGiftCard /> : <UserLogin />} />
        <Route path='/user/:email/forgot-password/:timeStamp' element={<ForgotPassword />} />

        {/* Payment Testing Routes */}
        <Route path='/payment' element={<PaymentForm />} />
        <Route path='/cancel' element={<div>Payment failure</div>} />

        {/* Page Not Found Route */}
        <Route path='/page-not-found' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
