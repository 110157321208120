import React from 'react';
import './index.css';
import logoImage from '../../../assets/website-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="logo-sec">
              <img src={logoImage} alt="Website logo image" />
              <div className="tag-line"><a href="#">support@example.com</a></div>
              <div className="tag-line"><a href="#">01234 56789</a></div>

              <div className="icon">
                <a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="inner-text">
              <div className="head">Useful Links</div>
              <ul>
                <li><a href="https://www.ezcurrent.com">Home</a></li>
                <li><a href="https://www.ezcurrent.com/about.html">About Us</a></li>
                <li><a href="https://app.ezcurrent.com/user/electricity">Services</a></li>
              </ul>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="inner-text">
              <div className="head">Help & Supports</div>
              <ul>
                <li><a href="https://app.ezcurrent.com/user/electricity">Purchase Electricity</a></li>
                <li><a href="https://app.ezcurrent.com/user/giftcard">Purchase Gift Card</a></li>
                <li><a href="https://app.ezcurrent.com/user/redeem">Redeem Gift Card</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">Copyright@2023. All Right Reserved.</div>
    </footer>
  );
}

export default Footer;
