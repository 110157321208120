import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Form from './Form';
import Footer from '../common/Footer';
import Header from '../common/Header';
import NavigationCard from './NavigationCard';

const PurchaseElectricity = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const [formData, setFormData] = useState({
    Amount: '',
  });

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
  };

  const handleLogout = () => {
    localStorage.removeItem('user-access-token');
    navigate('/user/login');
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('user-access-token');
    setUsername(localStorage.getItem('user_name'));
    if (accessToken) {
      navigate('/user/electricity');
    } else {
      navigate('/user/login');
    }
  }, []);

  return (
    <div>
      <Header
        handleLogout={handleLogout}
      />

      <Form
        title={'Purchase Electricity'}
        description={`Welcome Back ${username}`}
        fields={['Amount']}
        fieldValues={formData}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        suggestedAmount={['$ 20', '$ 50', '$ 100', '$ 200', '$ 500', '$ 1000']}
      />

      <div className='navigation-cards-container'>
        <NavigationCard title='Purchase Gift Card' navigationUrl='/user/giftcard' />
        <NavigationCard title='Redeem Gift Card' navigationUrl='/user/redeem' />
      </div>

      <Footer />
    </div>
  );
}

export default PurchaseElectricity;
